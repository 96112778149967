import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import * as Apis from "../components/Apis";
import * as Common from '../components/Common';
import Loading from '../components/Loading';
import { isMobile } from 'react-device-detect';

import ImgMachine from "../images/img-machine.png";
import ImgMachine_M from "../images/m/img-machine.png";
import IconProcess1 from "../images/icon-process-1.png";
import IconProcess2 from "../images/icon-process-2.png";
import IconProcess3 from "../images/icon-process-3.png";
import IconProcess4 from "../images/icon-process-4.png";
import IconProcess5 from "../images/icon-process-5.png";
import IconProcess6 from "../images/icon-process-6.png";
import IconHome2_1 from "../images/icon-home-2-1.png";
import IconHome2_2 from "../images/icon-home-2-2.png";
import IconHome2_3 from "../images/icon-home-2-3.png";
import IconHome2_1_M from "../images/m/icon-home-2-1.png";
import IconHome2_2_M from "../images/m/icon-home-2-2.png";
import IconHome2_3_M from "../images/m/icon-home-2-3.png";
import BannerKakaotalk from "../images/img-kakaotalk-banner.png";
import BannerKakaotalk_M from "../images/m/img-kakaotalk-banner.png";
import LogoFooter from "../images/logo-footer.png";

const settingsForTopBg = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 700,
  autoplay: true,
  autoplaySpeed: 4000,
  // useCSS: true,
  touchMove: true,
  pauseOnHover: true,
  fade: true
};

const settingsForCase = {
  arrows: false,
  infinite: true,
  speed: 700,
  useCSS: true,
  fade: true,
};
const settingsForCaseThumb = {
  arrows: false,
  infinite: true,
  speed: 700,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToScroll: 1,
  pauseOnHover: true,
};

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      topBgCurrIdx: 0,
      topBgNextIdx: 0,
      caseNextIdx: 0,
      caseCurrIdx: 0,
      section: "Home",
      isLoading: false,
      isDialogOpen: false,
      dialogType: "Alert",
      dialogMsg: "",
      isAgree: false,
      userName: "",
      userTel: "",
      userWantArea: "",
      wantTime: "",
      isBuildingOwner: 0,
      ask: "",
      isBannerKakaotalk: true
    };
  }

  componentDidMount() {
    let _hash = this.props.location.hash;
    if(_hash) {
      this._moveSection(_hash.substr(1));
    }
  }

  _onScroll = (e) => {
    let scrollTop = this.Container.scrollTop;

    let HomeStartPos = this.Home.getBoundingClientRect().y;
    let HomeEndPos = this.Competitive.getBoundingClientRect().top;
    let CompetitiveStartPos = this.Competitive.getBoundingClientRect().y;
    let CompetitiveEndPos = this.Case.getBoundingClientRect().top;
    let CaseStartPos = this.Case.getBoundingClientRect().y;
    let CaseEndPos = this.Process.getBoundingClientRect().top;
    let ProcessStartPos = this.Process.getBoundingClientRect().y;
    let ProcessEndPos = this.Consulting.getBoundingClientRect().top;
    let ConsultingStartPos = this.Consulting.getBoundingClientRect().y;
    let ConSultingEndPos = this.Footer.getBoundingClientRect().top;

    if(HomeStartPos < scrollTop < HomeEndPos) {
      this.setState({ section: "Home" });
    } else if(CompetitiveStartPos < scrollTop < CompetitiveEndPos) {
      this.setState({ section: "Competitive" });
    } else if(CaseStartPos < scrollTop < CaseEndPos) {
      this.setState({ section: "Case" });
    } else if(ProcessStartPos < scrollTop < ProcessEndPos) {
      this.setState({ section: "Process" });
    } else if(ConsultingStartPos < scrollTop < ConSultingEndPos) {
      this.setState({ section: "Consulting" });
    }
  };

  _moveSection(section) {
    let _section = section.substr(0, 1).toUpperCase() + section.substr(1);
    this.setState({ section: _section });
    let timer = setTimeout(() => {
      if(this[_section].scrollIntoView) {
        this[_section].scrollIntoView({ behavior: "smooth" });
      }
      clearTimeout(timer);
    }, 0);
  }


  _beforeChangeForTopBg(before, next) {
    this.setState({ topBgNextIdx: next });
  }

  _afterChangeForTopBg(idx) {
    this.setState({ topBgCurrIdx: idx });
  }

  _beforeChangeForCase(before, next) {
    this.setState({ caseNextIdx: next, caseCurrIdx: -1 });
    this.caseSlide.slickGoTo(next);
  }

  _afterChangeForCase(idx) {
    this.setState({ caseCurrIdx: idx });
  }

  _moveSlideThumb(idx) {
    this.caseSlide.slickGoTo(idx);
    this.caseSlideThumb.slickGoTo(idx);
  }

  _reload() {
    window.location.reload();
  }

  _doOpenDialog(msg, dialogType = "Alert") {
    this.setState({
      isDialogOpen: true,
      dialogMsg: msg,
      dialogType: dialogType
    });
  }
  _doCloseDialog() {
    this.setState({ isDialogOpen: false });

    let timer = setTimeout(() => {
      if(this.state.dialogType === "Complete" || this.state.dialogType === "Cancel") {
        this._reload();
      }
      clearTimeout(timer);
    }, 500);
  }

  _doOpenPopup(isOpen) {
    this.setState({ isPopupOpen: isOpen });
  }

  _doRequestConfirm() {
    this._doOpenDialog("상담접수를 하시겠습니까?", "Confirm");
  }

  _doRequest() {
    let _userName = this.state.userName;
    if(!_userName) {
      this._doOpenDialog("성명을 입력해주세요.");
      return;
    }

    let _userTel = this.state.userTel;
    if(!_userTel) {
      this._doOpenDialog("휴대전화번호를<br/>입력해주세요.");
      return;
    }
    if(!Common.isPhoneNumberValidation(_userTel)) {
      this._doOpenDialog("휴대전화번호 형식에<br/>맞지 않습니다.");
      return;
    }
    let _userTel1 = _userTel.substring(0, 3);
		let _userTel2 = _userTel.substring(3, _userTel.length === 10 ? 6 : 7);
		let _userTel3 = _userTel.substring(_userTel.length === 10 ? 6 : 7, _userTel.length === 10 ? 10 : 11);

    if(!this.state.isAgree) {
      this._doOpenDialog("개인정보 수집 및 이용에<br/>동의해주세요.");
      return;
    }

    this.setState({ isDialogOpen: false, isLoading: true });

    let ask = "";
    if(this.state.wantTime) {
      ask = "희망설치시기 : " + this.state.wantTime + "<br/>";
    }
    ask += this.state.ask;
    Apis.insertFranchise("VENDING", _userName, _userTel1, _userTel2, _userTel3, this.state.userWantArea, this.state.isBuildingOwner, ask).then(({ loading, data: { result, resultCode, resultMessage } }) => {
      this.setState({ isLoading: false });
			if (resultCode === "S1000") {
        window.kakaoMoment();
        window.naverScript();
        this._doOpenDialog("신청이 완료되었습니다.", "Complete");
			} else {
				this._doOpenDialog("문제가 발생하였습니다.<br/>다시 시도 해주세요.");
      }
    });
  }

  _doRequestCancel() {
    this._doOpenDialog("상담접수를<br/>취소하시겠습니까?", "Cancel");
  }

  render() {
    return (
      <div id="IndexComponent">
        <div id="Header" className="Header">
          <div className="HeaderArea">
            <Link to="/"><div className="Logo"></div></Link>
            <div className="MenuWrap">
              <ul>
                <li className={ this.state.section === "Home" ? "select" : "" }>
                  <Link to={{ pathname: "/", hash: "Home" }} title="HOME" onClick={e => this._moveSection("Home")}>HOME</Link>
                </li>
                <li className={ this.state.section === "Competitive" ? "select" : "" }>
                  <Link to={{ pathname: "/", hash: "Competitive" }} title="COMPETITIVE" onClick={e => this._moveSection("Competitive")}>경쟁력</Link>
                </li>
                <li className={ this.state.section === "Case" ? "select" : "" }>
                  <Link to={{ pathname: "/", hash: "Case" }} title="CASE" onClick={e => this._moveSection("Case")}>설치사례</Link>
                </li>
                <li className={ this.state.section === "Process" ? "select" : "" }>
                  <Link to={{ pathname: "/", hash: "Process" }} title="PROCESS" onClick={e => this._moveSection("Process")}>진행절차</Link>
                </li>
                <li className={ this.state.section === "Consulting" ? "select" : "" }>
                  <Link to={{ pathname: "/", hash: "Consulting" }} title="CONSULTING" onClick={e => this._moveSection("Consulting")}>상담신청</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="Container" ref={ref => {this.Container = ref}} onScroll={() => this._onScroll()}>
          <div id="Home" className="Home" ref={ref => {this.Home = ref}}>
            <Slider ref={ref => this.topBgSlide = ref} afterChange={(idx) => this._afterChangeForTopBg(idx)} beforeChange={(current, next) => this._beforeChangeForTopBg(current, next)} {...settingsForTopBg}>
              <div className="TopBgWrap TopBg1" style={{ background: '#eee' }}>
                <div className="SubContainer">
                  <div className="DescWrap">
                    <p className="SubTitle">가장 빠른 바리스타</p>
                    <p className="Title">
                      <span>FASTA</span>
                    </p>
                    <p className="Desc">지금은 무인창업 시대,</p>
                    <p className="Desc">24시간 일하는 똑똑한 스마트커피머신으로</p>
                    <p className="Desc">부가수익을 창출하세요<span className="italic">!</span></p>
                  </div>
                </div>
              </div>    
              <div className="TopBgWrap TopBg2" style={{ background: 'red' }}>
                <div className="SubContainer">
                  <div className="DescWrap">
                    <p className="SubTitle">가장 빠른 바리스타</p>
                    <p className="Title">
                      <span>FASTA</span>
                    </p>
                    <p className="Desc">지금은 무인창업 시대,</p>
                    <p className="Desc">24시간 일하는 똑똑한 스마트커피머신으로</p>
                    <p className="Desc">부가수익을 창출하세요<span className="italic">!</span></p>
                  </div>
                  <div className="SpecWrap PC">
                    <div className="SpecBox">
                      <div className="Top">
                        <p>2021<span>년</span></p>
                        <p>최신형<br/>무인커피머신 출시<span className="italic">!</span></p>
                      </div>
                      <div className="Bottom">
                        <p>핫 / 아이스 듀얼컵,<br/>32인치 터치모니터 등<br/>최신 기능 탑재<span className="italic">!</span></p>
                        <img src={ IconHome2_1 } alt="" />
                      </div>
                    </div>
                    <div className="SpecBox">
                      <div className="Top">
                        <p>24<span>시간</span></p>
                        <p>무인운영<span className="italic">!</span></p>
                      </div>
                      <div className="Bottom">
                        <p>인건비 0원<span className="italic">!</span><br/>수익률 UP<span className="italic">!</span></p>
                        <img src={ IconHome2_2 } alt="" />
                      </div>
                    </div>
                    <div className="SpecBox">
                      <div className="Top">
                        <p>소자본<span>으로 시작하는</span></p>
                        <p>부가수익 창출<span className="italic">!</span></p>
                      </div>
                      <div className="Bottom">
                        <p>일체형 기기1대면 OK<span className="italic">!</span><br/>렌탈 가능<span className="italic">!</span></p>
                        <img src={ IconHome2_3 } alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="SpecWrap MOBILE">
                    <div className="SpecBox">
                      <p>2021년 <b>최신형 무인커피머신 출시<span className="italic">!</span></b></p>
                      <p>핫 / 아이스 듀얼컵, 32인치 터치모니터 등 최신 기능 탑재<span className="italic">!</span></p>
                      <img src={ IconHome2_1_M } alt="" />
                    </div>
                    <div className="SpecBox">
                      <p>24시간 <b>무인운영<span className="italic">!</span></b></p>
                      <p>인건비 0원<span className="italic">!</span> 수익률 UP<span className="italic">!</span></p>
                      <img src={ IconHome2_2_M } alt="" />
                    </div>
                    <div className="SpecBox">
                      <p>소자본으로 시작하는 <b>부가수익 창출<span className="italic">!</span></b></p>
                      <p>일체형 기기1대면 OK<span className="italic">!</span> 렌탈 가능<span className="italic">!</span></p>
                      <img src={ IconHome2_3_M } alt="" />
                    </div>
                  </div>
                </div>
              </div>
              { !isMobile && <div className="TopBgWrap TopBg3" style={{ background: 'blue' }}>
                <div className="SubContainer">
                  <div className="DescWrap">
                    <p className="SubTitle">효율적인 무인카페 운영을 위한</p>
                    <p className="Title">
                      <span>fast barista, FASTA</span>
                    </p>
                    <p className="Desc">24시간 무인운영,</p>
                    <p className="Desc">소자본으로 시작하는 안정적인 부가수익 창출.</p>
                    <p className="Desc">2021년 최신형 무인커피머신의 성능을 경험하세요<span className="italic">!</span></p>
                  </div>
                </div>
              </div>
              }
            </Slider>
            <div className="NavWrap">
              <ul className="NavContainer">
                <li>
                  <button className={ this.state.topBgNextIdx === 0 ? "active" : "" } onClick={() => this.topBgSlide.slickGoTo(0)}></button>
                </li>
                <li>
                  <button className={ this.state.topBgNextIdx === 1 ? "active" : "" } onClick={() => this.topBgSlide.slickGoTo(1)}></button>
                </li>
                { !isMobile && 
                  <li>
                    <button className={ this.state.topBgNextIdx === 2 ? "active" : "" } onClick={() => this.topBgSlide.slickGoTo(2)}></button>
                  </li>
                }
              </ul>
            </div>
          </div>
          <div className="Tel">
            <a href="tel:02-6324-1400"><p>대표전화 02.6324.1400</p></a>
          </div>
          <div id="Competitive" className="Competitive" ref={ref => {this.Competitive = ref}}>
            <div className="SubContainer">
              <div className="TitleWrap">
                <p><span>스마트 무인커피머신, <b>FASTA</b></span>를 소개합니다.</p>
              </div>
              <div className="ContentWrap">
                <div className="Left">
                  <img src={ ImgMachine } alt="" className="PC" />
                  <img src={ ImgMachine_M } alt="" className="MOBILE" />
                </div>
                <div className="Right">
                  <div className="Grid">
                    <div className="GridRow">
                      <p className="Title">제품명</p>
                      <p className="Content">XM22BTM</p>
                    </div>
                    <div className="GridRow">
                      <p className="Title">규격, 중량</p>
                      <p className="Content">1950(H) x 1060(W) x 810(D), 250kg</p>
                    </div>
                    <div className="GridRow">
                      <p className="Title">소비전력</p>
                      <p className="Content">3000KW</p>
                    </div>
                    <div className="GridRow">
                      <p className="Title">KC인증여부</p>
                      <p className="Content">인증</p>
                    </div>
                    <div className="GridRow">
                      <p className="Title">디스플레이</p>
                      <p className="Content">32인치 터치형 키오스크</p>
                    </div>
                    <div className="GridRow">
                      <p className="Title">결제방식</p>
                      <p className="Content">카드결제, 삼성페이</p>
                    </div>
                    <div className="GridRow">
                      <p className="Title">머신 구성</p>
                      <p className="Content">제빙기, 에스프레소 추출부, 음료제조부, 컵토출부, 결제시스템</p>
                    </div>
                    <div className="GridRow">
                      <p className="Title">컵 적재 수량</p>
                      <p className="Content PC">총 400개<span>(종이컵/투명 플라스틱컵 각 200개)</span></p>
                      <p className="Content MOBILE">총 400개<span><br/>(종이컵/투명 플라스틱컵 각 200개)</span></p>
                    </div>
                    <div className="GridRow">
                      <p className="Title PC">동시 사용가능 재료 수</p>
                      <p className="Title MOBILE">동시 사용가능<br/>재료 수</p>
                      <p className="Content">원두 1종, 파우더 6종, 액상류 2종</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Competitive2">
            <div className="SubContainer">
              <div className="ContentContainer">
                <div className="TitleWrap">
                  <p>커피머신의 기능이</p>
                  <p>매출에 영향을 미치는 이유<span className="italic">!</span></p>
                  <p className="Desc PC">대용량, 고성능 무인커피머신 <b className="blue">FASTA</b>의 6가지 특장점을 확인해보세요<span className="italic">!</span></p>
                  <p className="Desc MOBILE">대용량, 고성능 무인커피머신<br/><b className="blue">FASTA</b>의 6가지 특장점을 확인해보세요<span className="italic">!</span></p>
                </div>
                <div className="ContentWrap">
                  <div className="ContentRow">
                    <div className="ContentBox">
                      <div className="Top"></div>
                      <div className="Bottom">
                        <div className="TitleTextWrap">
                          <p>16oz 빅사이즈</p>
                          <p className="Bold">핫 / 아이스 듀얼컵 사용<span className="italic">!</span></p>
                        </div>
                        <div className="DescTextWrap">
                          <p>핫 음료는 종이컵에, 아이스 음료는 플라스틱컵에 자동으로 인식하여 제공됩니다.</p>
                        </div>
                      </div>
                    </div>
                    <div className="ContentBox">
                      <div className="Top"></div>
                      <div className="Bottom">
                        <div className="TitleTextWrap">
                          <p>주문부터 음료제공까지</p>
                          <p className="Bold">원스탑, 일체형 머신<span className="italic">!</span></p>
                        </div>
                        <div className="DescTextWrap">
                          <p>제빙기, 컵 제공, 음료 제공 등</p>
                          <p>분리형 머신을 원스탑 일체형 머신으로<span className="italic">!</span></p>
                          <p>고객 주문 동선과 시간이 단축되어</p>
                          <p>보다 편리한 서비스를 제공합니다.</p>
                        </div>
                      </div>
                    </div>
                    <div className="ContentBox">
                      <div className="Top"></div>
                      <div className="Bottom">
                        <div className="TitleTextWrap">
                          <p>32인치</p>
                          <p className="Bold">터치형 키오스크<span className="italic">!</span></p>
                        </div>
                        <div className="DescTextWrap">
                          <p>대형 터치 키오스크를 탑재하여</p>
                          <p>매장 내 메뉴 홍보 기능과 고객 주문이 편리합니다.</p>
                        </div>
                      </div>
                    </div>
                    <div className="ContentBox">
                      <div className="Top"></div>
                      <div className="Bottom">
                        <div className="TitleTextWrap">
                          <p>최대 재료 사용량</p>
                          <p className="Bold">파우더 6종, 액상 2종</p>
                          <p className="Bold">동시 사용 가능<span className="italic">!</span></p>
                        </div>
                        <div className="DescTextWrap">
                          <p>동시에 활용 가능한 재료의 수가 많으면</p>
                          <p>판매 가능한 메뉴의 수도 많아집니다.</p>
                        </div>
                      </div>
                    </div>
                    <div className="ContentBox">
                      <div className="Top"></div>
                      <div className="Bottom">
                        <div className="TitleTextWrap">
                          <p>주문과 동시에 추출되는</p>
                          <p className="Bold">리얼 에스프레소<span className="italic">!</span></p>
                        </div>
                        <div className="DescTextWrap">
                          <p>분말 커피가 아닌,</p>
                          <p>즉석에서 추출되는 진짜 에스프레소<span className="italic">!</span></p>
                          <p>커피 전문점과 같은 풍부한 맛을 선사합니다.</p>
                        </div>
                      </div>
                    </div>
                    <div className="ContentBox">
                      <div className="Top"></div>
                      <div className="Bottom">
                        <div className="TitleTextWrap">
                          <p>깨끗한 물,</p>
                          <p className="Bold">정수 필터 시스템<span className="italic">!</span></p>
                        </div>
                        <div className="DescTextWrap">
                          <p>음료의 80%는 물,</p>
                          <p>정수 필터를 사용하여 깨끗하고</p>
                          <p>안전한 음료를 제공합니다.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="Case" className="Case" ref={ref => {this.Case = ref}}>
            <div className="SubContainer">
              <div className="Left">
                <div className="TitleWrap">
                  <p>추천장소 & 설치사례</p>
                  <p>무인빨래방, 아이스크림, 스터디카페 등 무인매장,
                  개인 무인카페, 샵인샵 카페, 사내카페,
                  관공서, 병원, 건물 내 유휴공간 등<br/>
                  다양한 장소에 설치되어 부가수익을 창출합니다.</p>
                  <p>※ 21년 출시 모델 전국 53대 운영중 (‘21.10. 기준)</p>
                </div>
                <div className="ElementWrap">
                  <Slider ref={ref => this.caseSlideThumb = ref} afterChange={(idx) => this._afterChangeForCase(idx)} beforeChange={(current, next) => this._beforeChangeForCase(current, next)} className="Slide" {...settingsForCaseThumb} slidesToShow={ isMobile ? 5 : 3 } >
                    {[...Array(8)].map((n, index) => {
                      return <div key={index} className={`Thumb${index + 1} Img ${this.state.caseCurrIdx === index ? 'Active' : ''}`} onClick={() => this._moveSlideThumb(index)}></div>
                    })}
                  </Slider>
                </div>
              </div>
              <div className="Right">
                <div className="Wrap">
                  <div className="ElementWrap">
                    <Slider ref={ref => this.caseSlide = ref} afterChange={(idx) => this._afterChangeForCase(idx)} beforeChange={(current, next) => this._beforeChangeForCase(current, next)} className="Slide" {...settingsForCase}>
                      {[...Array(8)].map((n, index) => {
                        return <div key={index} className={`Element${index + 1} Img`}></div>
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="Process" className="Process" ref={ref => {this.Process = ref}}>
            <div className="SubContainer">
              <div className="ContentContainer">
                <div className="TitleWrap">
                  <p>진행 절차</p>
                  <p className="Desc PC">물품 발주에서 설치까지 통상 1개월이 소요됩니다.<br/>더 빠른 설치를 희망하시는 경우, 개별 상담 시 말씀주세요.</p>
                  <p className="Desc MOBILE">물품 발주에서 설치까지 통상 1개월이 소요됩니다.<br/>더 빠른 설치를 희망하시는 경우,<br/> 개별 상담 시 말씀주세요.</p>
                </div>
                <div className="ContentWrap">
                  <div className="ContentRow">
                    <div className="ContentBox">
                      <p className="Step">STEP. 1</p>
                      <img src={IconProcess1} alt="" />
                      <p className="Title">상담 & 설치 요건 전달</p>
                      <p className="Desc">ㆍ설치 적합 환경의 요건 전달<br/>
                      ㆍ담당자 현장 확인</p>
                    </div>
                    <div className="ContentBox">
                      <p className="Step">STEP. 2</p>
                      <img src={IconProcess2} alt="" />
                      <p className="Title">물품 발주</p>
                      <p className="Desc">ㆍ발주서 제출<br/>
                      ㆍ설치 희망일 재 확인<br/>
                      ㆍ선급금 입금 (1천만원)</p>
                    </div>
                    <div className="ContentBox">
                      <p className="Step">STEP. 3</p>
                      <img src={IconProcess3} alt="" />
                      <p className="Title">행정처리 안내</p>
                      <p className="Desc">ㆍ사업자등록증 발급 안내</p>
                      <p className="Desc">ㆍ영업 신고 절차 안내</p>
                      <p className="Desc">ㆍ기타 준비사항 안내</p>
                    </div>
                    <div className="ContentBox">
                      <p className="Step">STEP. 4</p>
                      <img src={IconProcess4} alt="" />
                      <p className="Title">설치장소 재 확인, 점검</p>
                      <p className="Desc">ㆍ정상 설치에 차질이 없도록</p>
                      <p className="Desc">설치일 이전, 유선으로 재 확인</p>
                    </div>
                    <div className="ContentBox">
                      <p className="Step">STEP. 5</p>
                      <img src={IconProcess5} alt="" />
                      <p className="Title">설치, 레시피 설정</p>
                      <p className="Desc">ㆍ기기 설치<br/>
                      ㆍ메뉴 및 레시피 설정, 시음<br/>
                      ㆍ기기 작동법 교육</p>
                    </div>
                    <div className="ContentBox">
                      <p className="Step">STEP. 6</p>
                      <img src={IconProcess6} alt="" />
                      <p className="Title">GRAND OPEN</p>
                      <p className="Desc">ㆍ기기 상태 점검 및 A/S는<br/>
                      카카오톡 전용 채널로<br/>
                      접수 및 처리</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="Consulting" className="Consulting" ref={ref => {this.Consulting = ref}}>
            <div className="SubContainer">
              <div className="TitleWrap">
                <p>상담접수</p>
                <p className="Desc">상담 신청 정보를 남겨주시면 빠른 시일 내에<br/>연락 드리겠습니다.</p>
                {/* <p className="MOBILE">(*가 표시된 항목은 필수입력 항목입니다.)</p> */}
              </div>
              <div className="MainContent">
                <div className="Request">
                  <p className="MainTitle">신청자 정보 <span>(* 필수입력 항목)</span></p>
                  <div className="Content">
                    <div className="Row">
                      <div className="Title">성명 *</div>
                      <div className="Input">
                        <input type="text" name="name" onKeyUp={e => this.setState({ userName: e.target.value })} />
                      </div>
                    </div>
                    <div className="Row">
                      <div className="Title">휴대전화 *</div>
                      <div className="Input">
                        <input type="tel" maxLength="11" onKeyUp={e => this.setState({ userTel: e.target.value })} />
                      </div>
                    </div>
                    <div className="Row">
                      <div className="Title">설치장소 확보 여부</div>
                      <div className="Radio isBuildingOwner">
                        <label className="Label"> 있음
                          <input type="radio" name="isBuildingOwner" defaultChecked onChange={() => this.setState({ isBuildingOwner: 0 })} />
                          <span className="Checkmark"></span>
                        </label>
                        <label className="Label"> 아직없음
                          <input type="radio" name="isBuildingOwner" onChange={() => this.setState({ isBuildingOwner: 1 })} />
                          <span className="Checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className="Row">
                      <div className="Title">설치 예정 장소</div>
                      <div className="Input">
                        <input type="text" onKeyUp={e => this.setState({ userWantArea: e.target.value })} />
                      </div>
                    </div>
                    <div className="Row">
                      <div className="Title">희망 설치 시기</div>
                      <div className="Radio">
                        <label className="Label"> 1개월 이내
                          <input type="radio" name="wantTime" defaultChecked onChange={() => this.setState({ wantTime: "1개월 이내" })} />
                          <span className="Checkmark"></span>
                        </label>
                        <label className="Label"> 2개월 이내
                          <input type="radio" name="wantTime" onChange={() => this.setState({ wantTime: "2개월 이내" })} />
                          <span className="Checkmark"></span>
                        </label>
                        <label className="Label"> 그 이상
                          <input type="radio" name="wantTime" onChange={() => this.setState({ wantTime: "그 이상" })} />
                          <span className="Checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className="Row">
                      <textarea name="ask" placeholder="기타 전달 말씀, 문의사항 있으신 경우 자유롭게 기재 해 주세요."  onKeyUp={e => this.setState({ ask: e.target.value })}></textarea>
                    </div>
                  </div>
                </div>
                <div className="Privacy">
                  <p className="MainTitle">개인정보 수집 및 이용안내</p>
                  <div className="Content">
                    <div className="Row">
                      <p>
                        아래와 같이 고객님의 개인정보를 수집하고 있습니다.<br/><br/>
                        <span>개인정보 수집범위 :</span> 성명, 휴대전화, 문의내용<br/>
                        <span>개인정보 수집 및 이용목적 :</span> 구매상담 신청 확인 및 조회<br/>
                        <span>개인정보 수집 및 보유기간 :</span> 이용자의 개인정보는 원칙적으로
                        개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기하며
                        보유기간은 최대 3년을 넘기지 않는 것을 원칙으로 한다.
                      </p>
                    </div>
                    <div className="Row">
                      <label className="Label"> 개인정보 수집 및 이용에 동의합니다.
                        <input type="checkbox" name="isAgree" value="true" onChange={() => this.setState({ isAgree: !this.state.isAgree })} />
                        <span className="Checkmark"></span>
                      </label>
                    </div>
                    <div className="Row BtnArea">
                      <button className="Request" onClick={() => this._doRequestConfirm()}>신청</button>
                      <button className="Cancel" onClick={() => this._doRequestCancel()}>취소</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="Footer" className="Footer PC" ref={ref => {this.Footer = ref}}>
            <div className="SubContainer">
              <div className="Left">
                <div className="FooterLogo">
                  <img src={ LogoFooter } alt="" />
                </div>
                <div className="Address">
                  (주)피투피시스템즈 | 사업자등록번호 110-81-53273<br/>
                  서울특별시 서대문구 연세로 10-1 즐거운빌딩 9층<br/>
                  ⓒ2021. P2PSYSTEMS Corp.
                </div>
              </div>
              <div className="Right">
                <div className="Contact">
                  <div className="Top">
                    <a href="https://fastcafe.co.kr" className="Link" target="_blank" rel="noopener noreferrer"><p>매장형 가맹 창업 문의 바로가기</p></a>
                    <p><a href="tel:02-6324-1400" className="RepresentTel">대표전화&nbsp;&nbsp;&nbsp;&nbsp;02-6324-1400</a></p>
                  </div>
                  <div className="Bottom">
                    <a href="https://blog.naver.com/fastcafe2021/" target="_blank" rel="noopener noreferrer">
                      <button className="Naver"></button>
                      <span>블로그</span>
                    </a>
                    <a href="https://www.instagram.com/fastcafe_business/?igshid=p3q5advkbm81" target="_blank" rel="noopener noreferrer">
                      <button className="Instagram"></button>
                      <span>인스타그램</span>
                    </a>
                  </div>
                </div>  
              </div>
            </div>
          </div>  
          <div className="Footer MOBILE">
            <div className="SubContainer">
              <a href="https://fastcafe.co.kr" className="Link" target="_blank" rel="noopener noreferrer"><p>매장형 가맹 창업 문의 바로가기</p></a>
              <div className="Address">
                (주)피투피시스템즈<br/>
                사업자등록번호 110-81-53273<br/>
                서울특별시 서대문구 연세로 10-1 즐거운빌딩 9층<br/>
                ⓒ2021. P2PSYSTEMS Corp.
              </div>
              <div className="Bottom">
                <a href="https://www.instagram.com/fastcafe_business/?igshid=p3q5advkbm81" target="_blank" rel="noopener noreferrer">
                  <button className="Instagram"></button>
                  <span>인스타그램</span>
                </a>
                <a href="https://blog.naver.com/fastcafe2021/" target="_blank" rel="noopener noreferrer">
                  <button className="Naver"></button>
                  <span>블로그</span>
                </a>
                <span className="RepresentTel"><a href="tel:02-6324-1400">대표전화&nbsp;&nbsp;&nbsp;&nbsp;02-6324-1400</a></span>
              </div>
            </div>
          </div>  
        </div>

        <div className="FloatKakaotalk PC">
          <a href="http://pf.kakao.com/_brips/chat" target="_blank" rel="noreferrer"><img src={BannerKakaotalk} alt="" /></a>
        </div>
        {
          this.state.isBannerKakaotalk &&
          <div className="FloatKakaotalk MOBILE">
            <button className="BtnCloseBanner" onClick={() => this.setState({ isBannerKakaotalk: false })}></button>
            <a href="http://pf.kakao.com/_brips/chat" target="_blank" rel="noreferrer"><img src={BannerKakaotalk_M} alt="" /></a>
          </div>
        }
        
        <div id="Dialog" className={"LayerWrap " + (this.state.isDialogOpen ? "on" : "" )}>
          <div className="PopLayer">
            <div className="PopContainer">
              <p className="Msg" dangerouslySetInnerHTML={{ __html: this.state.dialogMsg }}></p>
            </div>
            <div className="BtnGroup">
              <button className="Ok" onClick={() => this.state.dialogType === "Confirm" ? this._doRequest() : this._doCloseDialog()}>확인</button>
              <button className={"Cancel " + this.state.dialogType} onClick={() => this._doCloseDialog()}>취소</button>
            </div>
            <button className="BtnClose" onClick={() => this._doCloseDialog()}></button>
          </div>
        </div>

        {this.state.isLoading && <Loading />}
      </div> 
    )
  }
}

export default Index;
