import axios from "axios";
import qs from "qs";
import * as Consts from "./Consts";

const instance = axios.create({
	baseURL: Consts.API_URL,
	headers: {
		"Content-Type": "application/x-www-form-urlencoded"
	},
	timeout: 5000
});

instance.interceptors.request.use(
	function (config) {
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	function (response) {
		return { loading: false, ...response };
	},
	function (error) {
		Promise.reject(error);
		return {
			loading: false,
			data: {
				resultCode: "E9999",
				resultMessage: `서버와 통신이 원활하지 않습니다.<br />잠시 후 다시 시도해 주세요.`,
				result: null
			}
		};
	}
);

export const insertFranchise = async (branchType, userName, userTel1, userTel2, userTel3, userWantArea, isBuildingOwner, ask) => {
	return instance.post(`${Consts.CLOSE_API_URL}/insertFranchise`, qs.stringify({ branchType: branchType, userName: userName, userTel1: userTel1, userTel2: userTel2, userTel3: userTel3, userWantArea: userWantArea, isBuildingOwner: isBuildingOwner, ask: ask, accessToken: Consts.ACCESS_TOKEN }));
};