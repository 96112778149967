import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './css/common.scss';
import './css/index.scss';

import Index from './routes/Index';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <Router>
        <div id="content">
          <Switch>
            <Route exact path={["", "/"]} component={Index} />
          </Switch>
        </div> 
      </Router>
    );
  }
}

export default App;
